<!-- 
    这个是用来做 显示数据的表格的
-->
<template>
<div>
    <el-button type="primary" style="width: 15%" @click="selectAllPages">选择全部词条</el-button>
    <span style="margin-left: 10px">已选择个数：{{ selectedRows.length }}</span>
    <el-table   :data="data_for_table.records"  v-if="pageType && data_for_table" @selection-change="handleSelectionChange"
                ref="multipleTable">
      <!-- 添加选择列 -->
      <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column v-for="(item,index) in column_name"  :key="index"
                    :width="item.width" :label="item.label" :prop="item.prop">
            <!-- 防止某一列中的数据过长，导致某些行的高度偏高，在这里如果长度超过一定长度，会自动缩减到30个字符，然后全部内容会用一个小窗口显示 -->
            <template slot-scope="scope">
                <el-popover	width="500"	trigger="hover"	:content="scope.row[item.prop]"
                        v-if="(typeof scope.row[item.prop] == 'string') && scope.row[item.prop].length >40">
                    <span slot="reference">{{scope.row[item.prop].slice(0,40)+'...'}}</span>
                </el-popover>
                <span	v-else>
                    {{item.map_type=='base'||item.map_type=='boolean'?item.map[ scope.row[item.prop] ]:scope.row[item.prop]}}
                </span>
            </template>
        </el-table-column>
        <!-- 编辑和删除 -->
        <el-table-column  align="right" fixed="right"  width="500" >
            <template slot-scope="scope">	
                <el-button  size="mini"  @click="show_link_generate_dialog(scope.row)">链接生成</el-button>
                <el-button  size="mini"  @click="page_path(scope.row,pageType_path_handler,bigType,pageType)">页面路径</el-button>
                <el-button  size="mini"  @click="show_dialog('edit',scope.$index)">编辑</el-button>
                <el-button  size="mini"  type="danger"  @click="delete_row(scope.row)">删除</el-button>
                <el-button	size="mini"	 type="warning"	icon="el-icon-top"
                    @click="sortIndexIncrease(scope.row.id);">
                    向上
                </el-button>
                <el-button	size="mini"	 type="warning"	icon="el-icon-bottom"
                    @click="sortIndexDecrease(scope.row.id);">
                    向下
                </el-button>
            </template>
        </el-table-column>
    </el-table>
    <!-- 下面的东西 -->
    <div class="foot">
        <el-button type="primary" @click="show_dialog('add')" size="small" class="button">新建一条记录</el-button>
        <el-pagination class="page"  background  layout="prev, pager, next" 	:current-page="data_for_table.current"	
                :page-size="data_for_table.size" :total="data_for_table.total"		@current-change="change_page"></el-pagination>
    </div>

    <el-dialog  :visible.sync="dialog.visible"  width="900px" append-to-body>
        <div class="form"   v-if="dialog.visible" >
            <div class="row" v-for="(item,index) in column_name"  :key="index" :class="item.map_type == 'textarea'?'bigger_row':''">
                <div class="label_container"><label>{{item.label}}</label></div>
                <div class="input_container">
                    <!-- 普通映射类型 -->
                    <el-select  v-if="item.map_type== 'base' " v-model="dialog.temp_data[item.prop]" placeholder="请选择">
                        <el-option v-for="(item1,index1) in item.map" :label="item1" :value='index1'	:key="index1"></el-option> 
                    </el-select>
                    <!-- 下面这个是应对map的key值为boolean型 -->
                    <el-select  v-else-if ="item.map_type== 'boolean'" v-model="dialog.temp_data[item.prop]" placeholder="请选择">
                        <el-option  :label="item.map[false]" :value='false'></el-option>
                        <el-option  :label="item.map[true]" :value='true'></el-option>
                    </el-select>
                    <MALL_select    :mallGoodId.sync="dialog.temp_data[item.prop]"   v-else-if="item.map_type == 'MALL'"></MALL_select>
                    <!-- 选择多个商品 -->
                    <mall_goods_list    class="mall_goods_list" v-else-if="item.map_type=='MALL_GOODS_LIST'"
                            :input_list.sync="dialog.temp_data[item.prop]"></mall_goods_list>
                    <!-- 客服词条选择 -->
                    <customer_service_faq class="customer_service_faq" v-else-if="item.map_type=='customer'"
                                            :input_list.sync="dialog.temp_data[item.prop]"></customer_service_faq>

                    <!-- 普通的文字类型 -->
                    <el-input v-else-if="item.map_type!='image'" :type="item.map_type=='textarea'?'textarea':'text'"
                            v-model="dialog.temp_data[item.prop]" placeholder="暂时为空"></el-input>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialog.visible = false">取 消</el-button>
            <el-button type="primary" @click="dialog_confirm">确 定</el-button>
        </span>
    </el-dialog>
    <!-- 生成链接弹窗 -->
    <el-dialog  :visible.sync="dialog_for_link_generate.visible"    width="1500px" append-to-body>
        <el-form    label-width="150px">
            <el-form-item label="小程序">
                <small_routine_select   
                    :appId.sync="dialog_for_link_generate.form.appId"   
                    @appletType="e=>{dialog_for_link_generate.form.appletType = e}"
                    @batchClassId="e=>{dialog_for_link_generate.form.batchClassId = e}"
                    @appName="e=>{dialog_for_link_generate.form.appName=e}">
                </small_routine_select>
            </el-form-item>
            <div>
                <el-form-item label="当前小程序">
                    <link_generated 
                        :appId="dialog_for_link_generate.form.appId" 
                        :appName="dialog_for_link_generate.form.appName"
                        :bigType ="bigType" 
                        :row="dialog_for_link_generate.row"    
                        :pageType_path_handler="pageType_path_handler"
                        :smallType = "pageType"
                        :generate_page_path_fn = "generate_page_path"
                        :customer_service_faq = "dialog_for_link_generate.form.customer_service_faq"
                        >
                    </link_generated>
                </el-form-item>
            </div>
        </el-form>
    </el-dialog>
</div>
</template>
    
<script>
import { mapState } from 'vuex';
import axios from 'axios';
import * as utility from '@/services/wCommonFunction.js'

import small_routine_select from './data_table/small_routine_select.vue'
import MALL_select from './data_table/MALL.vue'
import link_generated from './data_table/link_generated.vue'
import mall_goods_list from "@/components/thirdParty/smallRoutine/applet1/childrenPage/smallroutineConfiguration/mall_goods_list.vue"
import customer_service_faq from "@/components/thirdParty/smallRoutine/applet1/childrenPage/smallroutineConfiguration/customer_service_faq.vue"

export default{
    components:{
        MALL_select,small_routine_select,link_generated,mall_goods_list,customer_service_faq
    },
    props: {
        //后端page返回的数据
        //records属性为记录，current为当前页，pages为总页码
        //例如：{records:[],current:1,pages:1}
        data_for_table:{
            type:Object,
            default:null
        },
        column_name:{
            type:Array,
        },
        // 小类
        pageType:{
            type:String,
        },
        pagePath:{
            type:String,
        },
        bigType:{
            type:String ,
        }
    },
    data() {
        return {
            dialog:{
                visible:false,
                show_content_type:null,
                temp_data:{}
            },
            dialog_for_link_generate:{
                visible:false,
                row:{},
                form:{
                    appId:null,
                    appName:null,
                    appletType:null,
                    batchClassId:null,
                    customer_service_faq: [] // 此字段来接收customer_service_faq，客服词条的id
                },
                current_row:null,
                current_pageType:null,
                generated_link:null,
                generated_link_list:[]
            },
            //这里填写page中获取到的参数
            //key为页面类型
            //value返回页面要的参数
            pageType_path_handler:{
                MALL:row=>({getById:row.mallGoodId}),
                MAP_OF_POI_SEARCH:row=>({mapOfPoiSearchKeywords:row.mapOfPoiSearchKeywords}),
                MALL_ALL_GOODS:()=>({appContent:'mall'}),
                MALL_GOODS_LIST:(row)=>({mallGoodsIdList:this.input_list2goods_ids(row.mallGoodsList)}),
                SCAN:()=>({appContent:'scan'}),

                SIGN:()=>({appContent:'sign'}),
                SCAN2:()=>({appContent:'scan2'}),
                OVERTURN:()=>({appContent:'overturn'}),
                TURNPLATE:()=>({appContent:'turnplate'}),
                LUCKY_DRAW:()=>({appContent:'lucky_draw'}),
                TAKING_CAR:()=>({appContent:'taking_car'}),
                CUSTOMER_SERVICE:()=>({appContent:'customerService'}),
            },
          customer_input_list:null,
          selectedRows: [], // 存储选中的行
        }
    },
    methods: {
      //显示弹窗
      show_dialog(show_content_type, index) {
        this.dialog.visible = true;
        this.dialog.show_content_type = show_content_type;
        if (show_content_type == 'add') {	//如果是添加窗口
          this.dialog.temp_data = {};
          this.dialog.temp_data.pageType = this.pageType;
          this.dialog.temp_data.pagePath = this.pagePath;
          this.dialog.temp_data.customerServiceFaq = [];
        }
        if (show_content_type == 'edit') {	//如果是编辑窗口
          this.dialog.temp_data = this.data_for_table.records[index];
        }
      },
      //弹窗的确认按钮
      dialog_confirm() {
        // 遍历所有字段，检查是否有 URL 编码的 customer_service_faq 字段
        this.column_name.forEach((item) => {
          if (item.map_type === 'customer') {
            // 这里的 dialog.temp_data[item.prop] 是 URL 编码后的字符串
            // 检查该字段是否有值，防止报错
            if (this.dialog.temp_data[item.prop]) {
              // 将其从 URL 编码转换为 字符串
              this.dialog.temp_data[item.prop] = decodeURI(this.dialog.temp_data[item.prop]);
            }
          }
        });
        if (this.dialog.show_content_type == 'edit') {
          this.edit_row(this.dialog.temp_data);
        } else {
          this.add_row(this.dialog.temp_data);
        }
      },
      //展示链接生成的弹窗
      show_link_generate_dialog(row) {
        this.dialog_for_link_generate.visible = true;
        this.dialog_for_link_generate.row = row;
        // 将 customer_service_faq 的 input_list 传递给 dialog_for_link_generate.form
        this.dialog_for_link_generate.form.customer_service_faq = JSON.parse(this.customer_input_list);
      },
      // 生成链路上的参数
      page_path(row, pageType_path_handler, bigType, smallType) {
        this.copyText(this.generate_page_path(row, pageType_path_handler, bigType, smallType));
      },
      // 生成page的链路
      generate_page_path(row, pageType_path_handler, bigType, smallType) {
        let url_param = {}
        if (pageType_path_handler[bigType]) {
          url_param = pageType_path_handler[bigType](row);
        }
        if (pageType_path_handler[smallType]) {
          url_param = {...url_param, ...pageType_path_handler[smallType](row)};
        }
        let service_url = row.pagePath + '?title=' + row.name;
        Object.keys(url_param).forEach(
            key => {
              service_url = service_url + '&' + key + '=' + url_param[key];
            }
        )
        return service_url;
      },
      // 编辑操作
      async edit_row(row) {
        const result = await axios.put(this.global_url + 'tb-third-party-wang-redpacket-menu-page/update', row);
        if (result.data.code == 200) {
          this.$message({message: '更改成功', type: 'success', center: true});
        } else {
          this.$message({message: '更改失败', type: 'error', center: true});
        }
        setTimeout(() => {
          this.change_page(this.data_for_table.current);
        }, 700);
        this.dialog.visible = false;
      },
      //	添加操作
      async add_row(row) {
        const result = await axios.post(this.global_url + 'tb-third-party-wang-redpacket-menu-page/add', row);
        if (result.data.code == 200) {
          this.$message({message: '添加成功', type: 'success', center: true});
        } else {
          this.$message({message: '添加失败', type: 'error', center: true});
        }
        setTimeout(() => {
          this.change_page(this.data_for_table.current);
        }, 700);
        this.dialog.visible = false;
      },
      //删除某行记录
      async delete_row(row) {
        let url = this.global_url + "tb-third-party-wang-redpacket-menu-page/del/" + row.id;
        const res = await axios.delete(url);
        const data = utility.handleResponse(res);
        if (data == true) {
          this.$message.success("成功删除");
          this.$emit("page", 1);
        } else {
          this.$message.error("删除失败");
        }
      },
      //更改页数
      change_page(page_index) {
        this.$emit("page", page_index);
      },

      // 复制输入的内容到剪切板上面
      copyText(text) {
        if (text == "") {
          this.$message.error("");
        }
        const textArea = document.createElement('textArea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        this.$message.success("已经复制完毕");
      },

      //排名向前
      async sortIndexIncrease(id) {
        const res = await axios({
          url: this.global_url + "tb-third-party-wang-redpacket-menu-page/sortIndexIncrease",
          method: "POST",
          data: {id: id}
        });
        const data = utility.handleResponse(res);
        if (data == true) {
          this.$message.success("成功增加排序");
          this.$emit("page", 1);
        } else {
          this.$message.error("操作失败");
        }
      },
      //排名向后
      async sortIndexDecrease(id) {
        const res = await axios({
          url: this.global_url + "tb-third-party-wang-redpacket-menu-page/sortIndexDecrease",
          method: "POST",
          data: {id: id}
        });
        const data = utility.handleResponse(res);
        if (data == true) {
          this.$message.success("成功降低排序");
          this.$emit("page", 1);
        } else {
          this.$message.error("操作失败");
        }
      },

      //用来处理input_list
      //输入是字符串（json）的input_list
      //输出是把is_check:false（没有选中）的商品去掉的input_list
      input_list2goods_ids(json_input_list) {
        let goods_ids = [];
        let input_list = JSON.parse(json_input_list);
        input_list.forEach(e =>
            e.goodsIdList
                .filter(i => i.isCheck)
                .forEach(i => goods_ids.push(i.goodsId))
        );
        return JSON.stringify(goods_ids);
      },
      async selectAllPages(){
        const { data: res } = await this.$http.get(
            "/tb-third-party-wang-redpacket-menu-page/pageByTypeList?type=" + this.pageType,
        );
        // 清空选中的行
        this.selectedRows = [];
        // 清空表格的选择
        this.$refs.multipleTable.clearSelection();
        this.handleSelectionChange(res.message);
      },
      handleSelectionChange(val) {
        // 选中行变化时的回调函数
        this.selectedRows = val.map(row => row.id);
        const serviceData = {
          selectedRows: this.selectedRows,  // 选中的行 ID 数组
          urlData: {},    // URL 键值对数组
        };

        const bigType = this.bigType;
        // 检查 bigType 的处理函数是否存在
        const handlerBigType = this.pageType_path_handler[bigType];
        if (!handlerBigType) {
          return;
        }
        // 仅处理第一行或任意一行
        const result = handlerBigType(val[0]);
        if (result) {
          Object.entries(result).forEach(([urlKey, urlValue]) => {
            serviceData.urlData = { urlKey, urlValue };
          });
        }
        // 通过事件发送 serviceData
        this.$emit('sendServiceData', serviceData);
      },
    },
    computed:{
		...mapState({
            global_url:state=>state.global_base_url.main
        })
        
    },watch: {
    'dialog.temp_data.customerServiceFaq': {
      handler(newVal) {
        // 可以在这里将最新的 input_list 保存到其他地方
        this.customer_input_list = decodeURIComponent(newVal);
      },
      immediate: true, // 立即执行，获取初始值
      deep: true // 如果 input_list 是一个对象或数组，确保深度监听
    }
  },
  created(){
        
    },
}
</script>
    
<style  lang="less" scoped>
.form{
    display: flex;
    flex-wrap: wrap;
}
.foot{
    width: 100%;
    height: 8%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    .button{
        margin-left: 10px;
    }
}
.form .row{
    display: flex;
    width: 800px;
    .label_container{
        display: flex;
        justify-content: right;
        align-items: center;
        width: 100px;
        height: 70px; 
        color: #888888;
    }
    .input_container{
        display: flex;
        justify-content: left;
        align-items: center;
        margin-left: 20px ;
        flex:1;
        // 图片的
        .avatar-uploader{
            width: 50px;
            height: 50px;
            .div_main_img{
                width: 80px;
                height: 80px;
            }
        }
        .mall_goods_list{
            margin-top: 20px;
        }
        .customer_service_faq{
            width: 100%;
        }
    }
    
}
    
</style>