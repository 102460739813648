<template>
    <div		class="container">
		<div	class="mask"  v-if="is_show_mask">
			<div 	class="loading_text"	>正在加载中</div>
			<img  class="loading_icon"	src="./image/Loading.svg"	/>
		</div>
		<el-button type="primary" @click="add">添加</el-button>
		<el-button type="primary" @click="clear">清空</el-button>
		<el-table	:data="list">
				<el-table-column  label="生活号" width="350">
						<template slot-scope="scope">
								<!--<el-input v-model="scope.row.id" placeholder="id号"	clearable></el-input>-->
              <el-select v-model="scope.row.id" placeholder="请选择">
                <el-option
                  v-for="item in lifeInfoList"
                  :key="item.id"
                  :label="item.lifeName + '（' + item.lifeAppId + '）'"
                  :value="item.id">
                </el-option>
              </el-select>
						</template>
				</el-table-column>
				<el-table-column  label="排序"	width="150">
						<template slot-scope="scope"	>
								<el-button type="primary" @click="row_up(scope.$index)"	:disabled="scope.$index==0"	size="mini">向上</el-button>
								<el-button type="primary" @click="row_down(scope.$index)" :disabled="scope.$index==list.length-1"	size="mini">向下</el-button>
						</template>
				</el-table-column>
				<el-table-column  label="操作"  width="80">
						<template slot-scope="scope"	>
								<el-button type="primary" @click="delete_row(scope.$index)" size="mini">删除</el-button>
						</template>
				</el-table-column>
		</el-table>
    </div>
</template>

<script>
import axios from 'axios';
import store from "@/store";

export default{
    components:{
		
    },
    props: {
		input_list:{	type:String },
		list_max_length:{	type:Number, default:5}
    },
    data() {
        return {
          // 生活号管理页面中的生活号列表
          lifeInfoList: [],
			// 这个是数据列表，在created那边有初始化，对input_list就是输入进来的String格式的列表进行json的转化，
			// 转化为Array类型的列表
            list:[],
			is_first_watch:true,
			is_show_mask:true,
        }
    },
    methods:{
      // 获取生活号管理页面中的生活号列表
      async getLifeInfoList() {
        const res = await axios.get(store.state.global_base_url.main + 'life/list')
        this.lifeInfoList = res.data.message
      },
//对数据进行增删改查的操作
		//添加一行数据
        add(){
			if(this.list_max_length == this.list.length)	{	//如果长度超过了，就不要再添加了
				this.$message.error("超过长度了,无法再添加");
			}else{
				this.list.push({sort:this.sort_default})	//添加
			}
        },
		//排序的名次上升，指排序的下标上升
        row_up(index){
        	let temp = this.list[index] ;
        	this.$set(this.list,index,this.list[index-1]);
        	this.$set(this.list,index-1,temp);
        },
		//排序的名次下降，指排序的下标下降
        row_down(index){
        	let temp = this.list[index] ;
        	this.$set(this.list,index,this.list[index+1]);
        	this.$set(this.list,index+1,temp);
        },
		//删除一行的数据
        delete_row(index){
        	this.list.splice(index,1);
        },
		//清空所有的数据
        clear(){
        	this.list =[];
        },
		
		
//下面处理初始化数据的，因为props进来的数据比较慢，需要等props内的参数进来才开始初始化的操作
		//根据输入进来的数据 处理
		initiailize_data(){
			//先进行解码（在网络传输中会变内容，所以传输的时候会进行编码解码）
			//后对于JSON格式经行 转化
			this.list = JSON.parse(decodeURI(this.input_list)) ;
		},
		//在本地数据还没有初始化之前，把页面内容遮盖，然后轮询props是否初始化完成，再进行本地数据初始化
		wait_util_not_undefined(callbackFunction){
			const  intervalTimer = setInterval(
				()=>{
					if(this.input_list != undefined){
						this.initiailize_data();
						clearInterval(intervalTimer);
						this.is_show_mask = false; 
					}
				},
				100
			)
		}
    },
    computed:{
		//求出新的一行的排序值
		sort_default(){
			if(this.list.length == 0){
				return 0;
			}else{
				let max = this.list[0].sort ;
				this.list.forEach(	(item)=>{		if(item.sort>max)	max=item.sort;	}	)
				return max+1;
			}
		},
	},
    created(){
		this.wait_util_not_undefined();
    this.getLifeInfoList()
    },
	watch:{
		//对结果进行解码，上传给父组件
		list:{
			deep:true,
			handler(newValue){
				this.$emit("update:input_list",encodeURI(JSON.stringify(newValue)));
			}
		}
	}
}
</script>

<style  lang="less" scoped>
	.container{
		position: relative;
	}
	.mask{
		position: absolute;
		z-index: 200;
		background-color: white;
		opacity: 0.8;
		width: 100%;
		height: 100%;
		
		display: flex;	justify-content: center;	align-items: center;
		
		.loading_text{
			font-size: 23px;
		}
		
		.loading_icon{
			margin-left: 6px;
			width: 30px;		height: 30px;
			animation-name: rotate;
			animation-duration: 2s;
			animation-timing-function: linear;
			animation-iteration-count: infinite;
		}
		@keyframes rotate {
			0%		{	transform: rotate(0deg);	}
			50%		{	transform: rotate(180deg);	}
			100%		{	transform: rotate(360deg);	}
		}
	}
</style>