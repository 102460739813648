<template>
  <div>
    <el-button type="primary" :loading="useDeployLoading" @click="multiServiceApply(1)">{{ useDeployLoading? '加载中' : '执行选择的类别' }}</el-button>
    <el-button type="warning" plain :loading="useDeployLoading" @click="multiServiceApply(2)">{{ useDeployLoading? '加载中' : '执行选择的第三方' }}</el-button>

    <div class="text">服务类目 ------>></div>
    <el-cascader
        v-model="selectedValues"
        :options="categoryList"
        :props="{ expandTrigger: 'hover' }"
        @change="categoryListChangeHandler"
    ></el-cascader>

    <div class="text">客服词条 ------>></div>
    <defaultConfig @sendServiceData="handleServiceData"></defaultConfig>
    <div style="margin-bottom: 20px;">
      <span class="spantext1">小程序的总任务的数量：{{appletAmount}}</span>
      <span class="spantext2">小程序已完成的数量：{{count}}</span>
    </div>
    <div>
      <span class="spantext1">客服的总任务的数量：{{service_appletAmount}}</span>
      <span class="spantext2">客服的已完成的数量：{{service_count}}</span>
    </div>
  </div>
</template>

<script>
import defaultConfig from "../../other/redpacket/menuPage/defaultConfig.vue";
import categoryTypeOption from "../../../thirdParty/smallRoutine/zkyTemplate/constant/category-type-option";

let intervalId = null

export default {
  components: {
    defaultConfig
  },
  props: [
    "checkedCities",
    "dimensionArr",
    "appletTypeIcpStatusList"
  ],
  data() {
    return {
      //加载中
      useDeployLoading: false,
      //小程序
      appletAmount: 0,
      count: 0,
      //失败信息列表
      messages: [],
      redisKey: null,
      //客服
      service_appletAmount: 0,
      service_count: 0,

      // 默认选中的值
      selectedValues: [],
      // 小程序类目列表
      categoryList: categoryTypeOption,

      serviceApplyVo: {
        // 行业类目ID
        categoryId: "",
        // 行业类目名称
        categoryName: "",
        // 服务提报选中的行id
        selectRowIds: [],
        // 服务提报需要的链接生成的数据
        urlData: {},
      }

    }
  },
  methods: {
    //服务类目和客服词条数据：
    categoryListChangeHandler(selected) {
      let list = this.categoryList;
      let selectedNode = null;
      // 遍历selected数组，逐级查找
      for (let level = 0; level < selected.length; level++) {
        selectedNode = list.find(item => item.value === selected[level]);
        if (!selectedNode) {
          return;
        }
        // 继续查找下一级的children，除非已经是最后一个层级
        if (level < selected.length - 1) {
          list = selectedNode.children; // 更新list为下一层级的children
        }
      }
      // 当遍历完selected后selectedNode就是用户最终选中的节点
      if (selectedNode) {
        // 在这里处理你选中的节点，赋值给post的categoryID
        this.serviceApplyVo.categoryId = selectedNode.value;
        this.serviceApplyVo.categoryName = selectedNode.label;
      }
    },
    handleServiceData(serviceData){
      this.serviceApplyVo.selectRowIds = serviceData.selectedRows;
      this.serviceApplyVo.urlData = serviceData.urlData;
    },

    //获取 第三方类型的 区分未备案/已备案 =》 返回的是 icpStatus 值（NOT_REGISTER、REGISTER_SUCCESS）
    checkIcpStatus2(appletTypeIcpStatusList) {
      // 检查是否所有元素的checked属性都为true
      if (appletTypeIcpStatusList.every(item => item.checked)) {
        return null;
      }
      // 找到第一个checked为true的元素并返回其icpStatus值
      const found = appletTypeIcpStatusList.find(item => item.checked);
      return found ? found.icpStatus : null;
    },

    //执行服务提报
    async multiServiceApply(is) {
      this.useDeployLoading = true

      let batchClassIdList = this.checkedCities.map(city => city.id); //选中的类别
      let appletTypeListList = this.dimensionArr.map(city => city.id); //选中的第三方
      if (is === 1 && batchClassIdList.length < 1) {
        this.$message.error("请选择类别");
        this.useDeployLoading = false
        return
      }
      if (is === 2 && appletTypeListList.length < 1) {
        this.$message.error("请选择第三方");
        this.useDeployLoading = false
        return
      }
      let appletTypeIcpStatus = this.checkIcpStatus2(this.appletTypeIcpStatusList)

      let multiAppletTypeOrBatchClassIdVo = {
        batchClassIdList: is === 1? batchClassIdList : null,
        appletTypeList: is === 2? appletTypeListList : null,
        appletTypeIcpStatus: appletTypeIcpStatus,
        serviceApplyVo: this.serviceApplyVo
      }

      //计算要执行多少小程序
      await this.countAppletAmount(multiAppletTypeOrBatchClassIdVo);

      //执行服务提报
      const { data: res } = await this.$http.post(
          "/thirdParty2/multiServiceApply",
          multiAppletTypeOrBatchClassIdVo
      );
      this.$message.warning("「批量服务提报」已提交，等待审核结果");
      this.redisKey = res.message;

      //查询进度
      this.startTaskProgress();
    },
    //进度条
    async startTaskProgress() {
      this.count = 0; //已完成的数量
      intervalId = setInterval(async () => {
        //查询 查询批量审核的进度
        const { data: res } = await this.$http.get(
            "/redisTasksBufferController/getTask?taskId=" + this.redisKey
        );
        console.log("服务提报进度：", res.message)

        if (res.message != null) {
          //小程序已完成进度
          this.count = res.message.auditedAccomplishCount? res.message.auditedAccomplishCount : '';
          //失败信息列表
          this.messages = res.message?.multiAuditApplySbuMsgVoSet? res.message?.multiAuditApplySbuMsgVoSet : [];
          //客服已完成进度（已完成的小程序数 * 每个小程序需要执行的客服数 - 失败信息列表）
          this.service_count = this.count * this.serviceApplyVo.selectRowIds.length - this.messages.length;
          // console.log("this.count", this.count)
          // console.log("this.serviceApplyVo.selectRowIds", this.serviceApplyVo.selectRowIds)

          /**
           * 1 * 3 - 1 = 3 -1 = 2
           * 2 * 3 - 3 = 6 - 3 = 3
           * 3 * 3 - 3 = 9 - 3 = 6
           *
           */
          // this.service_count = this.service_appletAmount - this.messages.length;
          //传递给父组件
          this.$emit('anomalydelivery', this.messages);
        }

        //所有任务进度完成
        if (this.count == this.appletAmount) {
          //解除按钮等待状态
          this.useDeployLoading = false;
          //清除定时器
          clearInterval(intervalId);
          //触发父组件 -> 更新小程序列表的数据
          this.$emit('getList');
          this.$message.success("「批量服务提报」执行完成");
        }
      }, 5000); // 每5秒查询一次
    },
    //获取 计算要执行多少小程序
    async countAppletAmount(multiAppletTypeOrBatchClassIdVo) {
      //后台计算有多少小程序
      const { data: res } = await this.$http.post(
          "/tb-third-party-wang-batch-class/countAppletAmount",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.appletAmount = res.message;
        //计算总共有多少客服（所有小程序 * 每个小程序需要执行的客服数）
        this.service_appletAmount = this.appletAmount * this.serviceApplyVo.selectRowIds.length;
      } else {
        this.$message.error("计算进度条进度失败:" + res.message);
      }
    },
  },
  created() {

  }
}
</script>

<style scoped lang="less">
.text{
  margin-top: 30px;
  margin-bottom: 10px;
}
.spantext1{
  color: #af3434;
  font-size: 20px;
  margin-right: 50px;
}
.spantext2{
  color: #5daf34;
  font-size: 20px;
}
</style>