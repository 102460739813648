<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="toList()"
              icon="el-icon-arrow-left"
            >
              返回
            </el-button>
          </div>
        </el-row>
      </div>

      <!-- 按钮模块 -->
      <div class="div_button div_modle">
        <!-- <div class="div_title">执行按钮</div> -->
        <div class="div_button_modle">
          <div class="div_button_modle_title">构建小程序版本</div>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'upload' ? 'success' : 'primary'"
            @click="chooseMes('upload')"
          >
            上传版本
          </el-button>
          <!-- <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'listQuery' ? 'success' : 'primary'"
            @click="chooseMes('listQuery')"
          >
            版本列表查询
          </el-button> -->
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'buildQuery' ? 'success' : 'primary'"
            @click="chooseMes('buildQuery')"
          >
            查询版本构建状态
          </el-button>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'versionDelete' ? 'success' : 'primary'"
            @click="chooseMes('versionDelete')"
          >
            删除商家小程序版本
          </el-button>
          <el-button
            class="buttonSingle"
            :type="
              chooseButtonMes == 'experienceCreate' ? 'success' : 'primary'
            "
            @click="chooseMes('experienceCreate')"
          >
            生成商家小程序体验版
          </el-button>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'experienceQuery' ? 'success' : 'primary'"
            @click="chooseMes('experienceQuery')"
          >
            小程序体验版状态查询接口
          </el-button>
          <el-button
            class="buttonSingle"
            :type="
              chooseButtonMes == 'experienceCancel' ? 'success' : 'primary'
            "
            @click="chooseMes('experienceCancel')"
          >
            取消体验版
          </el-button>
          <el-button
            class="buttonSingle"
            :type="
              chooseButtonMes == 'appMembersCreate' ? 'success' : 'primary'
            "
            @click="chooseMes('appMembersCreate')"
          >
            添加开发者或体验者
          </el-button>
        </div>
        <div class="div_button_modle">
          <div class="div_button_modle_title">提交审核小程序</div>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'auditApply' ? 'success' : 'primary'"
            @click="chooseMes('auditApply')"
          >
            提交审核
          </el-button>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'auditCancel' ? 'success' : 'primary'"
            @click="chooseMes('auditCancel')"
          >
            撤销审核
          </el-button>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'auditedCancel' ? 'success' : 'primary'"
            @click="chooseMes('auditedCancel')"
          >
            退回开发
          </el-button>
          <el-button
              class="buttonSingle"
              :type="chooseButtonMes == 'serviceApply' ? 'success' : 'primary'"
              @click="chooseMes('serviceApply')"
          >
            服务提报
          </el-button>
        </div>
        <div class="div_button_modle">
          <div class="div_button_modle_title">上架商家小程序</div>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'online' ? 'success' : 'primary'"
            @click="chooseMes('online')"
          >
            上架
          </el-button>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'offline' ? 'success' : 'primary'"
            @click="chooseMes('offline')"
          >
            下架
          </el-button>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'rollback' ? 'success' : 'primary'"
            @click="chooseMes('rollback')"
          >
            回滚
          </el-button>
        </div>
        <div class="div_button_modle">
          <div class="div_button_modle_title">其他</div>
          <el-button
            class="buttonSingle"
            :type="chooseButtonMes == 'baseInfoModify' ? 'success' : 'primary'"
            @click="chooseMes('baseInfoModify')"
          >
            修改基础信息
          </el-button>
          <el-button
            class="buttonSingle"
            :type="
              chooseButtonMes == 'reasonForRejection' ? 'success' : 'primary'
            "
            @click="chooseMes('reasonForRejection')"
          >
            查看最近一次驳回原因
          </el-button>
        </div>
      </div>
      <!-- 输入模块 -->
      <div class="div_input div_modle">
        <div class="div_title">输入模块</div>
        <el-form :model="postParameter" ref="formMesRef" label-width="auto">
          <!-- 模板版本号 -->
          <el-form-item label="模板名称:" prop="">
            {{ templateMes.name }}
          </el-form-item>
          <el-form-item label="小程序名称:" prop="">
            {{ templateMes.smallRoutineName }}
          </el-form-item>
          <el-form-item
            label="模板版本号"
            prop="templateVersion"
            v-if="chooseButtonMes.match('upload')"
          >
            <el-select
              filterable
              v-model="postParameter.templateVersion"
              placeholder="请选择模板版本号"
            >
              <el-option
                v-for="item in templateMes.appVersionInfos"
                :key="item.app_version"
                :label="item.app_version + '(' + item.version_status_text + ')'"
                :value="item.app_version"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 商家小程序版本号(手动输入) -->
          <el-form-item
            label="商家小程序版本号"
            prop="appVersion"
            v-if="chooseButtonMes.match('upload')"
          >
            <el-input
              class="div_input_single"
              v-model="postParameter.appVersion"
              placeholder="请输入商家小程序版本号,例:0.0.1"
              maxlength="100"
              show-word-limit
              clearable
            ></el-input>
          </el-form-item>
          <!-- 商家小程序版本号(接口返回列表) -->
          <el-form-item
            label="商家小程序版本号"
            prop="appVersion"
            v-if="
              chooseButtonMes.match('buildQuery') ||
              chooseButtonMes.match('listQuery') ||
              chooseButtonMes.match('versionDelete') ||
              chooseButtonMes.match('experienceCreate') ||
              chooseButtonMes.match('experienceQuery') ||
              chooseButtonMes.match('experienceCancel') ||
              chooseButtonMes.match('auditApply') ||
              chooseButtonMes.match('auditCancel') ||
              chooseButtonMes.match('auditedCancel') ||
              chooseButtonMes.match('online') ||
              chooseButtonMes.match('offline') ||
              chooseButtonMes.match('rollback')
            "
          >
            <el-select
              filterable
              v-model="postParameter.appVersion"
              clearable
              placeholder="请选择模板版本号"
            >
              <el-option
                v-for="item in appVersionList.appVersionInfos"
                :key="item.app_version"
                :label="item.app_version + '(' + item.version_status_text + ')'"
                :value="item.app_version"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <template v-if="chooseButtonMes.match('appMembersCreate')">
            <!-- 添加开发者或体验者-->
            <el-form-item label="支付宝登录账号" prop="logonId">
              <el-input
                class="div_input_single"
                v-model="postParameter.logonId"
                placeholder="请输入支付宝登录账号"
                maxlength="100"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="角色类型" prop="role">
              <el-select
                filterable
                v-model="postParameter.role"
                placeholder="请选择角色类型"
              >
                <el-option
                  v-for="item in appMembersCreateRoleList"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
          <template v-if="chooseButtonMes.match('auditApply')">
            <el-form-item label="刷新描述">
              <auditTemplate  @overideInfo="overideAuditApplyInfo"></auditTemplate>
            </el-form-item>
            <!-- 提交审核 -->
            <!-- 小程序名称 -->
            <el-form-item label="应用名称" prop="appName">
              <el-input
                class="div_input_single"
                v-model="postParameter.appName"
                placeholder="请输入应用名称"
                maxlength="30"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="应用英文名称" prop="appEnglishName">
              <el-input
                class="div_input_single"
                v-model="postParameter.appEnglishName"
                placeholder="请输入应用英文名称"
                maxlength="30"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="小程序描述" prop="appDesc">
              <el-input
                type="textarea"
                class="div_input_single"
                v-model="postParameter.appDesc"
                placeholder="请输入版本描述"
                :rows="3"
                maxlength="200"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="版本描述" prop="versionDesc">
              <el-input
                type="textarea"
                class="div_input_single"
                v-model="postParameter.versionDesc"
                placeholder="请输入版本描述"
                :rows="3"
                maxlength="500"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="服务区域类型" prop="regionType">
              <el-select
                filterable
                v-model="postParameter.regionType"
                clearable
                placeholder="请选择服务区域类型"
              >
                <el-option
                  v-for="item in regionTypeList"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
            <el-button	@click="()=>{choose_region_data.dialogFormVisible = true}"	v-if="postParameter.regionType == 'LOCATION'">选择区域</el-button>
            <!-- 下面这个是弹窗，用来选择区域的 -->
            <chooseRegion	:dialogFormVisible = "choose_region_data.dialogFormVisible"		
              @dialogFormClose = "()=>{choose_region_data.dialogFormVisible = false}"
              @submit="(region_list)=>{postParameter.serviceRegionInfo = region_list }"></chooseRegion>	
			  
            </el-form-item>
            <el-form-item label="营业执照有效期" prop="licenseValidDate">
              <el-date-picker
                v-model="postParameter.licenseValidDate"
                type="date"
                placeholder="选择营业执照有效期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="客服电话" prop="servicePhone">
              <el-input
                class="div_input_single"
                v-model="postParameter.servicePhone"
                placeholder="请输入客服电话"
                maxlength="30"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="应用简介" prop="appSlogan">
              <el-input
                class="div_input_single"
                v-model="postParameter.appSlogan"
                placeholder="请输入小程序应用简介"
                maxlength="32"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="logo图标" prop="appLogo">
              <imageChoose  :image_url.sync="postParameter.appLogo"
                :is_need_compress="false"
                :available-type="['image/jpeg', 'image/png']"
                prefix_path="small_routine/audit_apply/logo">
              </imageChoose>
            </el-form-item>
            <category
              ref="categoryRef"
              :code="categoryMes.code"
              :type="categoryMes.type"
              :chooseMes="categoryMes.chooseMes"
              :applicationsId="categoryMes.applicationsId"
            />
          </template>
          <template v-if="chooseButtonMes.match('baseInfoModify')">
            <el-form-item label="应用名称">
              <el-input
                class="div_input_single"
                v-model="postParameter.appName"
                placeholder="请输入应用名称"
                maxlength="20"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="英文名称">
              <el-input
                class="div_input_single"
                v-model="postParameter.appEnglishName"
                placeholder="请输入英文名称"
                maxlength="20"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="应用简介">
              <el-input
                class="div_input_single"
                v-model="postParameter.appSlogan"
                placeholder="请输入应用简介"
                maxlength="32"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="应用描述">
              <el-input
                type="textarea"
                class="div_input_single"
                v-model="postParameter.offlineLink"
                placeholder="请输入应用描述"
                :rows="2"
                maxlength="200"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="客服电话">
              <el-input
                class="div_input_single"
                v-model="postParameter.servicePhone"
                placeholder="请输入客服电话"
                maxlength="30"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="客服邮箱">
              <el-input
                class="div_input_single"
                v-model="postParameter.serviceEmail"
                placeholder="请输入客服邮箱"
                maxlength="128"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
            <category
              ref="categoryRef"
              :chooseMes="categoryMes.chooseMes"
              :applicationsId="categoryMes.applicationsId"
            />
          </template>
          <template v-if="chooseButtonMes.match('serviceApply')">
            <el-form-item label="服务类目：">
              <el-cascader
                  v-model="selectedValues"
                  :options="categoryList"
                  :props="{ expandTrigger: 'hover' }"
                  @change="categoryListChangeHandler"
              ></el-cascader>
            </el-form-item>
            <defaultConfig @sendServiceData="handleServiceData"></defaultConfig>
          </template>
          <el-form-item label="">
            <el-button
              v-show="chooseButtonMes != 'reasonForRejection'"
              class="buttonSingle"
              type="primary"
              @click="submit('formMesRef')"
              :loading="useDeployLoading"
            >
              提交
            </el-button>
<!-- 			<el-button
			  v-show="chooseButtonMes != 'reasonForRejection'"
			  class="buttonSingle"
			  type="primary"
			  @click="submit('formMesRef')"
			>
				批量提交
			</el-button> -->
          </el-form-item>
		  
          <!-- 驳回原因 -->
          <reasonForRejection
            v-if="chooseButtonMes.match('reasonForRejection')"
            :showSingleMes="showSingleMes"
          />
        </el-form>
      </div>
      <!-- 结果展示 -->
      <div class="div_result div_modle">
        <div class="div_title">执行结果</div>
        <div class="div_result_mes">
          <div class="div_result_mes_success" v-show="resultMes.success">
            {{ resultMes.mes }}
          </div>
          <div class="div_result_mes_err" v-show="resultMes.err" v-html="resultMes.mes">
          </div>
          <el-link
            v-show="!isNull(resultMes.href)"
            type="primary"
            @click="toCard()"
          >
            查看链接
          </el-link>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import category from "../category/category.vue";
import reasonForRejection from "./examine/reasonForRejection.vue";

import chooseRegion from "./examine/chooseRegion.vue"
import auditTemplate from "./examine/auditTemplate.vue";
import imageChoose from "@/components/thirdParty/smallRoutine/applet1/childrenPage/smallroutineConfiguration/image_choose";
import categoryTypeOption from "../../thirdParty/smallRoutine/zkyTemplate/constant/category-type-option";
import defaultConfig from "../other/redpacket/menuPage/defaultConfig.vue";

export default {
  name: "examine",
  /**
   * qrCreate: 二维码扫码授权完跳转至该页面
   */
  props: ["showList", "showSingleMes", "refreshList", "qrCreate"],
  components: {
    category,
    reasonForRejection,
	  chooseRegion,
    auditTemplate,
    imageChoose,
    defaultConfig,
  },
  data() {
    return {
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
	  
		app_list:[],		//小程序列表,所有小程序的列表
	  
      formMesRules: {
        appVersion: [
          {
            required: true,
            message: "请输入商家小程序版本号",
            trigger: "blur",
          },
        ],
        templateVersion: [
          { required: true, message: "请输入模板版本号", trigger: "change" },
        ],
        logonId: [
          {
            required: true,
            message: "请输入被授权的支付宝登录账号",
            trigger: "blur",
          },
        ],
        role: [
          { required: true, message: "请选择角色类型", trigger: "change" },
        ],
        appName: [
          { required: true, message: "请输入小程序名称", trigger: "blur" },
          {
            min: 3,
            max: 30,
            message: "长度在 3 到 30 个字符",
            trigger: "blur",
          },
        ],
        appEnglishName: [
          {
            min: 3,
            max: 30,
            message: "长度在 3 到 30 个字符",
            trigger: "blur",
          },
        ],
        versionDesc: [
          {
            required: true,
            message: "请输入小程序版本描述",
            trigger: "blur",
          },
          {
            min: 30,
            max: 500,
            message: "长度在 30 到 500 个字符",
            trigger: "blur",
          },
        ],
        regionType: [
          { required: true, message: "请选择服务区域类型", trigger: "change" },
        ],
        licenseValidDate: [
          {
            required: true,
            message: "请选择营业执照有效期",
            trigger: "change",
          },
        ],
        servicePhone: [
          {
            required: true,
            message: "请输入小程序客服电话",
            trigger: "blur",
          },
          {
            min: 5,
            max: 30,
            message: "长度在 5 到 30 个字符",
            trigger: "blur",
          },
        ],
        appSlogan: [
          {
            required: true,
            message: "请输入小程序应用简介",
            trigger: "blur",
          },
          {
            min: 10,
            max: 32,
            message: "长度在 10 到 32 个字符",
            trigger: "blur",
          },
        ],
        appDesc: [
          {
            min: 20,
            max: 200,
            message: "长度在 20 到 200 个字符",
            trigger: "blur",
          },
        ],
      },
      postParameter: {
        // ———— 必填 ————
        // 小程序类型
        smallRoutineType: "",
        // appId
        appId: "",
        // 小程序模板 APPID
        templateId: "",
        // 商家appId
        merchantAppId: "",
        // 商家授权token
        appAuthToken: "",
        // 小程序模板版本号。版本号必须满足 x.y.z, 且均为数字。不传默认使用最新在架（已上架）模板版本。
        templateVersion: "",
        // 商家小程序版本号。版本号必须满足 x.y.z, 且均为数字。要求版本号比商户之前最新的版本号高。
        appVersion: "",
        // ———— 非必填 ————
        // 自定义参数，用于配置商家小程序的个性化内容，具体使用方法参见示例代码。
        ext: "",
        // 小程序投放的端参数，默认支付宝端
        bundleId: "",
        // ———— 添加开发者或体验者接口必填 ————
        logonId: "",
        role: "",
        // ———— 提交审核 ————
        // 小程序应用名称,长度限制 3~20 个字符
        appName: "",
        // 小程序应用英文名称,长度限制 3~20 个字符
        appEnglishName: "",
        // 小程序版本描述，30-500个字符
        versionDesc: "",
        // 小程序服务区域类型
        regionType: "CHINA",
		//	 小程序服务区域类型 如果选择LOCATION，那么就可以用这个来选择
		serviceRegionInfo:[],
        // 营业执照有效期，格式为 yyyy-MM-dd，9999-12-31表示长期有效。
        licenseValidDate: "9999-12-31",
        // 小程序客服电话，长度限制5~30个字符
        servicePhone: "05960000000",
        // 小程序应用简介，一句话描述小程序功能，长度限制 10~32个字符。
        appSlogan: "",
        // 小程序logo图标
        appLogo: "",
        // 小程序应用描述，长度限制 20~200 个字。
        appDesc: "",
        // 行业类目ID
        categoryId: "",
        // 行业类目名称
        categoryName: "",
        // 服务提报选中的行id
        selectRowIds: [],
        // 服务提报需要的链接生成的数据
        urlData: {},
		
		// firstSpecialLicensePic:"https://bfbz.vip/java_file/webapp/wang/small_routine/audit_apply/1.jpg",
		// secondSpecialLicensePic:"https://bfbz.vip/java_file/webapp/wang/small_routine/audit_apply/2.png",
		// thirdSpecialLicensePic:"https://bfbz.vip/java_file/webapp/wang/small_routine/audit_apply/3.jpg",
      },
      // 模板版本列表
      templateMes: {
        // 模板名称
        name: "",
        appVersionInfos: [],
        // 小程序名称
        smallRoutineName: "",
      },
      // 选择的方法
      chooseButtonMes: "",
      // 输入模块展示
      inputMes: {
        templateVersion: false,
        appVersion: false,
        appVersionSelect: false,
        appMembersCreate: false,
        // 提交审核
        auditApply: false,
      },
      // 商家小程序版本列表
      appVersionList: {
        appVersionInfos: [],
        versionList: [],
      },
      resultMes: {
        success: false,
        err: false,
        // 结果内容
        mes: "",
        // 需要跳转的链接地址
        href: "",
      },
      // 添加开发者或体验者角色类型
      appMembersCreateRoleList: [
        {
          label: "开发者",
          value: "DEVELOPER",
        },
        {
          label: "体验者",
          value: "EXPERIENCER",
        },
      ],
      // 服务区域类型
      regionTypeList: [
        {
          label: "全球",
          value: "GLOBAL",
          disabled: true,
        },
        {
          label: "中国",
          value: "CHINA",
          disabled: false,
        },
        {
          label: "指定区域",
          value: "LOCATION",
          disabled: false,
        },
      ],
	  //指定区域选择，当服务区域类型选择指定区域的是否才会显示
		choose_region_data: {
			dialogFormVisible:false,	//是否展示指定区域的选择
		},
      appLogoData: {
        appId: "",
      },
      // 类目
      categoryMes: {
        code: "",
        type: "",
        chooseMes: "",
        applicationsId: "",
      },
      //==> Yang
      appVersion: "", // 商家小程序版本号，这个只获取最新的版本号



      //==>
      // 默认选中的值
      selectedValues: [],
      // 小程序类目列表
      categoryList: categoryTypeOption,
      // redis的key
      taskId: null,
      // 按钮加载状态
      useDeployLoading: false,
    };
  },
  watch: {
    showSingleMes(e) {
      this.init(e);
    },
  },
  created() {
    this.init(this.showSingleMes);
  },
  methods: {
    // 初始化
    async init(e) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      await this.getExamineDefaultMes(e.templateId);
      if ("商家扫码授权小程序" != e.name) {
        this.$set(this.postParameter, "appName", e.name);
      }
      this.$set(this.postParameter, "appId", e.appId);
      this.$set(this.postParameter, "merchantAppId", e.appId);
      this.$set(this.templateMes, "smallRoutineName", e.name);
      await this.initPostParameter(e);
      let qrCreate = this.qrCreate;
      if (qrCreate == true) {
        // 模板授权扫码完跳转进入
        this.chooseMes("auditApply");
      } else {
        this.chooseMes("upload");
      }
      this.getTemplateVersion(e);
      this.listQuery();
      loading.close();
    },
    // 根据模板id获取默认填充内容
    async getExamineDefaultMes(e) {
      const { data: res } = await this.$http.get(
        "/thirdPartyTemplate/getExamineDefaultMes?id=" + e
      );
      console.log(res);
      let message = res.message;
      if (res.code != "200") {
        return this.$message.error("根据模板id获取默认填充内容失败:" + message);
      } 
      this.$set(this.postParameter, "appSlogan", message.appSlogan);
      this.$set(this.postParameter, "appDesc", message.appDesc);
      this.$set(this.postParameter, "versionDesc", message.versionDesc);
      this.$set(this.postParameter, "servicePhone", message.servicePhone);
      this.$set(this.postParameter, "appLogo", message.appLogo);
    },
    // async getTemplateAppIdById(id){
    //   this.postParameter.templateId = "";
    // },
    async initPostParameter(e) {
      let mes = await this.getIsvCodeByTemplateId(e.templateId);
      this.applicationsId = mes.applicationsId;
      this.$set(this.categoryMes, "applicationsId", mes.applicationsId);
      this.$set(this.categoryMes, "chooseMes", mes.category);
      // this.categoryMes.chooseMes = mes.category;
      // this.postParameter.code = mes.isvCode;
      // this.postParameter.merchantTableId = e.id;
      // this.postParameter.merchantAppId = e.merchantAppId;
      // this.postParameter.templateId = mes.merchantAppId;
      // this.postParameter.smallRoutineType = e.smallRoutineType;
      // this.postParameter.appAuthToken = e.appAuthToken;
      // this.categoryMes.type = mes.isvType;
      // this.categoryMes.code = mes.isvCode;
    },
    // 根据模板id获取模板数据
    async getIsvCodeByTemplateId(id) {
      const { data: res } = await this.$http.get(
        "/thirdPartyTemplate/getMesById?id=" + id
      );
      let message = res.message;
      if (res.code != "200") {
        return this.$message.error("版本列表查询失败:" + message);
      }
      return message;
    },
    toList() {
      this.showList();
    },
    // 获取模板版本号
    async getTemplateVersion(e) {
      const { data: res } = await this.$http.get(
        "/thirdPartyTemplate/listQuery?id=" + e.templateId
      );
      let message = res.message;
      if (res.code != "200") {
        return this.$message.error("获取模板版本号失败:" + message);
      }
      this.templateMes.name = message.name;
      this.templateMes.appVersionInfos = message.appVersionInfos;
      if (message.versionList.length == 0) {
        this.$message.warning("当前模板还未上传版本，请先上传版本");
      }
    },
    // 选择接口
    chooseMes(e) {
      let chooseButtonMes = this.chooseButtonMes;
      if (e == chooseButtonMes) {
        return;
      }
      this.initResultMes();
      // switch (e) {
      //   case "upload":
      //   case "appMembersCreate":
      //   case "auditApply":
      //   case "buildQuery":
      //   case "listQuery":
      //   case "versionDelete":
      //   case "experienceCreate":
      //   case "experienceQuery":
      //   case "experienceCancel":
      //   case "auditCancel":
      //     // 上传版本
      //     // 添加开发者或体验者
      //     // 提交审核
      //     // 查询版本构建状态
      //     // 版本列表查询
      //     // 删除商家小程序版本
      //     // 生成商家小程序体验版
      //     // 小程序体验版状态查询接口
      //     // 取消体验版
      //     // 撤销审核
      //     break;
      //   default:
      //     return this.$message.error("选择失败,请重新选择");
      // }
      this.$set(this, "chooseButtonMes", e);
      // this.chooseButtonMes = e;
    },
    // 上传版本
    async upload() {
      // let postParameter = this.postParameter;
      // let appVersion = postParameter.appVersion;
      // if (this.isNull(appVersion)) {
      //   this.$message.warning("请输入小程序版本号");
      //   return;
      // }
      // const { data: res } = await this.$http.post(
      //   "/thirdSmallRoutineTemplate/upload",
      //   this.loginMes
      // );
      // if (res.code == "200") {
      //   let mes = res.message;
      //   window.sessionStorage.setItem("userName", mes.name);
      //   window.sessionStorage.setItem("roleGrade", mes.roleGrade);
      //   window.sessionStorage.setItem("token", mes.token);
      //   // 默认登录账号
      //   window.localStorage.setItem("loginDefaultMes", this.loginMes.account);
      //   this.$notify({
      //     title: "登陆登录成功",
      //     type: "success",
      //   });
      //   this.$router.push({ path: "/home" });
      // } else {
      //   this.$message.error(res.message);
      // }
    },
    // 提交
    submit(formName) {
      // 表单提交信息校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      let chooseButtonMes = this.chooseButtonMes;
      let postParameter = this.postParameter;
      let categoryRef = this.$refs.categoryRef;
      if (!this.isNull(categoryRef)) {
        let category = categoryRef.getChooseCategoryMes();
        postParameter.miniCategoryIds = category;
      }
      const { data: res } = await this.$http.post(
        "/thirdParty2/" + chooseButtonMes,
        postParameter
      );
      let message = res.message;
      if (res.code == "200" && res.message == null || res.message == "操作成功") {
        let successMes = "操作成功";
        this.$message.success(successMes);
        switch (chooseButtonMes) {
          case "upload":
          case "versionDelete":
          case "auditApply":
          case "auditCancel":
          case "auditedCancel":
          case "online":
          case "offline":
          case "rollback":
            // 上传
            // 删除商家小程序版本
            // 提交审核
            // 撤销审核
            // 退回开发
            // 上架
            // 下架
            // 回滚
            this.listQuery();
            // 刷新列表
            this.refreshList();
            break;
          case "experienceQuery":
            // 小程序体验版状态查询接口
            this.resultMes.href = message.expQrCodeUrl;
            successMes = message.statusText;
            break;
          default:
            successMes = message;
            break;
        }
        if (chooseButtonMes == "auditApply") {
          // 更新页面小程序名称
          this.templateMes.smallRoutineName = postParameter.appName;
        }
        this.setResultSuccessMes(successMes);
        return;
      } else if (chooseButtonMes == "serviceApply"){
        this.useDeployLoading = true;
          if (200 === res.code) {
            this.$message.success("「提交审核」已提交，等待审核结果");
          } else {
            this.$message.error("「提交审核」提交失败:" + res.message);
          }
          this.taskId = res.message;
          //查询进度
          await this.startTaskProgress1();
      } else {
        this.$message.error(message);
        return this.setResultErrMes(message);
      }
    },
    async startTaskProgress1() {
      let count = 0; //已完成的数量
      let intervalId1 =  setInterval(async () => {
        //查询 查询批量审核的进度
        const { data: res } = await this.$http.get(
            "/redisTasksBufferController/getTask?taskId=" + this.taskId
        );
        if (res.message != null) {
          //进度
          count = res.message.auditedAccomplishCount? res.message.auditedAccomplishCount : 0;
          //失败信息列表
          if (res.message?.multiAuditApplySbuMsgVoSet && res.message.multiAuditApplySbuMsgVoSet.length > 0) {
            // 遍历 multiAuditApplySbuMsgVoSet 生成错误信息字符串
            let errorMessages = res.message.multiAuditApplySbuMsgVoSet
                .map(item => `${item.name}: ${item.operateMessage}`)
                .join('<br>');
            // 更新 resultMes 的错误信息和状态
            this.setResultErrMes(errorMessages)
          } else {
            // 如果没有失败信息，设置任务为成功
            this.setResultSuccessMes("任务进度正常，无错误信息")
          }
        }

        //所有任务进度完成
        if (count === this.postParameter.selectRowIds.length) {
          //解除按钮等待状态
          this.useDeployLoading = false;
          //清除定时器
          clearInterval(intervalId1);
          this.$message.success("「批量服务提报」执行完成");
        }
      }, 5000); // 每5秒查询一次
    },
    // 版本列表查询
    async listQuery() {
      const { data: res } = await this.$http.post(
        "/thirdParty2/listQuery",
        this.postParameter
      );
      let message = res.message;
      if (res.code != "200") {
        return this.$message.error("版本列表查询失败:" + message);
      }

      this.appVersion = message.appVersionInfos[0]['app_version']; //Yang 商家小程序版本号，最新的
      console.log("this.appVersion", this.appVersion);

      let appVersion = this.postParameter.appVersion;
      let appVersionInfos = message.appVersionInfos;
      let flay = true;
      for (let i = 0, length = appVersionInfos.length; i < length; i++) {
        if (appVersionInfos[i].app_version == appVersion) {
          flay = false;
          break;
        }
      }
      if (flay) {
        this.postParameter.appVersion = "";
      }
      this.appVersionList = message;
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
    setResultErrMes(e) {
      this.$set(this.resultMes, "success", false);
      this.$set(this.resultMes, "err", true);
      this.$set(this.resultMes, "mes", e);
    },
    setResultSuccessMes(e) {
      this.$set(this.resultMes, "success", true);
      this.$set(this.resultMes, "err", false);
      this.$set(this.resultMes, "mes", e);
    },
    initResultMes() {
      this.$set(this.resultMes, "success", false);
      this.$set(this.resultMes, "err", false);
      this.$set(this.resultMes, "mes", "");
      this.$set(this.resultMes, "href", "");
    },
    // 打开结果里的链接
    toCard() {
      let resultMes = this.resultMes;
      let href = resultMes.href;
      if (this.isNull(href)) {
        this.$message.warning("打开失败,跳转链接为空");
      }
      window.open(href);
    },
    getArrayBuffer(file) {
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = function (ev) {
        const result = ev.target.result;
        this.postParameter.buffer = result;
      };
      // return new Promise((resolve, reject) => {
      //   const reader = new FileReader();
      //   reader.readAsArrayBuffer(file);
      //   reader.onload = () => resolve(reader.result);
      //   reader.onerror = (error) => reject(error);
      // });
    },
    //覆盖内容
    overideAuditApplyInfo(e){
      let prop_map_apply = {
        logoImagePathList : ['appLogo'] ,
        digestList        : ['appSlogan'],
        describeList      : ['appDesc','versionDesc']
      };
      Object.keys(e).forEach(i=>{
        if(e[i] != null && e[i]!=undefined && e[i]!=""){
          let prop_list = prop_map_apply[i] ; //例如['appDesc','versionDesc']
          prop_list.forEach(j=>{
            this.postParameter[j] = e[i];
          })
        }
      })
    },
    categoryListChangeHandler(selected) {
      let list = this.categoryList;
      let selectedNode = null;
      // 遍历selected数组，逐级查找
      for (let level = 0; level < selected.length; level++) {
        selectedNode = list.find(item => item.value === selected[level]);
        if (!selectedNode) {
          return;
        }
        // 继续查找下一级的children，除非已经是最后一个层级
        if (level < selected.length - 1) {
          list = selectedNode.children; // 更新list为下一层级的children
        }
      }
      // 当遍历完selected后selectedNode就是用户最终选中的节点
      if (selectedNode) {
        // 在这里处理你选中的节点，赋值给post的categoryID
        this.postParameter.categoryId = selectedNode.value;
        this.postParameter.categoryName = selectedNode.label;
      }
    },
    handleServiceData(serviceData){
      this.postParameter.selectRowIds = serviceData.selectedRows;
      this.postParameter.urlData = serviceData.urlData;
    }
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  position: relative;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .div_modle {
    padding-top: 20px;
    .div_button_modle {
      .div_button_modle_title {
        padding-right: 20px;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
  .div_title {
    width: 100%;
    // background-color: aquamarine;
    // text-align: center;
    font-size: 30px;
    color: #626263;
    padding-bottom: 10px;
  }
  // 点击的按钮
  .div_button {
    .buttonSingle {
      margin-top: 10px;
    }
  }
  // 输入模块
  .div_input {
    .div_input_single {
      width: 60%;
    }
  }

  // 结果展示
  .div_result {
    // position: absolute;
    // bottom: 20px;
    width: 90%;
    .div_result_mes {
      border: 2px solid #ccc;
      border-radius: 10px;
      width: 100%;
      height: 200px;
      padding: 10px;
      overflow: auto;
      font-size: 20px;
      .div_result_mes_success {
        color: green;
      }
      .div_result_mes_err {
        color: red;
      }
    }
  }
  // 文件上传
  .avatar-uploader-icon,
  .imgAppLogo {
    height: 100px;
    width: 100px;
  }
  .avatar-uploader-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #d9d9d9;
    font-size: 25px;
  }
}
</style>
