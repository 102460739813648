
<template>
  <div>
    <el-divider content-position="left">
      <span class="Span">类别选择</span>
    </el-divider>
    <div>
      <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <el-button size="medium" type="primary" @click="switchover()" style="margin-left: 30px">{{ icpStatus }}</el-button>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
        <el-checkbox v-for="city in allCategoryList" :label="city" :key="city.id" style="margin-left: 20px; margin-top: 10px; width: 150px">
          {{city.name}}</el-checkbox>
      </el-checkbox-group>
    </div>

    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="Span">第三方选择</span>
    </el-divider>
    <div>
      <el-checkbox v-model="appletTypeIcpStatusList[0].checked">未备案</el-checkbox>
      <el-checkbox v-model="appletTypeIcpStatusList[1].checked">已备案</el-checkbox>

      <div style="margin: 15px 0;"></div>
      <el-checkbox v-model="checkAllType" @change="handleCheckAllChangeType">全选</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="dimensionArr" @change="handleCheckedCitiesChangeType">
        <el-checkbox v-for="city in tbThirdPartySmallRoutineType" :label="city" :key="city.id" style="margin-left: 20px; margin-top: 10px; width: 150px">
          {{city.name}}</el-checkbox>
      </el-checkbox-group>
    </div>

    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="SpanA">批量应用配置</span>
    </el-divider>
    <div>
      <el-button type="primary" @click="useDeploy()" :loading="useDeployLoadingA">
        {{ useDeployLoadingA? '加载中' : '执行选择的类别' }}</el-button>
      <el-button type="warning" plain @click="useDeploythirdparty()" :loading="useDeployLoadingA">
        {{ useDeployLoadingA? '加载中' : '执行选择的第三方' }}</el-button>
      <div style="margin-top: 30px">
        <el-progress :text-inside="true" :stroke-width="22" status="success" :percentage="percentage2"></el-progress>
      </div>
    </div>

    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="SpanA">批量上传版本</span>
    </el-divider>
    <div>
      <el-button type="primary" @click="updateVersions(1)" :loading="useDeployLoadingC">
        {{ useDeployLoadingC? '加载中' : '执行选择的类别' }}</el-button>
      <el-button type="warning" plain @click="updateVersions(2)" :loading="useDeployLoadingC">
        {{ useDeployLoadingC? '加载中' : '执行选择的第三方' }}</el-button>
      <h3 style="color: #dd6161">总任务数：{{appletAmount3}}</h3>
      <h3 style="color: #5daf34">已完成：{{count3}}</h3>
<!--      <div style="margin-top: 30px">-->
<!--        <el-progress :text-inside="true" :stroke-width="22" status="success" :percentage="percentage3"></el-progress>-->
<!--      </div>-->
    </div>


    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="SpanA">批量提交审核</span>
    </el-divider>
    <div>
      <div>
        <el-button type="primary" @click="submit_multi_auditApply(1)" :loading="useDeployLoadingB">
          {{ useDeployLoadingB? '加载中' : '执行选择的类别' }}</el-button>
        <el-button type="warning" plain @click="submit_multi_auditApply(2)" :loading="useDeployLoadingB">
          {{ useDeployLoadingB? '加载中' : '执行选择的第三方' }}</el-button>
      </div>
      <div style="margin-top: 30px">刷新描述，非必选 ------>></div>
      <el-button	@click="optionModule = ''" type="info" style="margin-right: 5px">清除选择</el-button>
      <el-radio-group v-model="optionModule" v-for="(item,index) in optionModuleList" :key="index" >
        <el-radio-button :label="item.groupName" style="margin: 10px"></el-radio-button>
      </el-radio-group>
      <h3 style="color: #dd6161">总任务数：{{appletAmount1}}</h3>
      <h3 style="color: #5daf34">已完成：{{count}}</h3>
    </div>

<!--    <div class="fg"></div>-->
<!--    <el-divider content-position="left">-->
<!--      <span class="SpanA">批量驳回退回开发</span>-->
<!--    </el-divider>-->
<!--    <div>-->
<!--      <el-button type="primary" @click="multiAuditedCancel(1)" :loading="useDeployLoadingD">-->
<!--        {{ useDeployLoadingD? '加载中' : '执行选择的类别' }}</el-button>-->
<!--      <el-button type="warning" plain @click="multiAuditedCancel(2)" :loading="useDeployLoadingD">-->
<!--        {{ useDeployLoadingD? '加载中' : '执行选择的第三方' }}</el-button>-->
<!--      <div style="margin-top: 30px">-->
<!--        <el-progress :text-inside="true" :stroke-width="22" status="success" :percentage="percentage4"></el-progress>-->
<!--      </div>-->
<!--    </div>-->

        <div class="fg"></div>
        <el-divider content-position="left">
          <span class="SpanA">批量服务提报</span>
        </el-divider>
        <div>
          <batchServicePresentation
              :checkedCities="checkedCities"
              :dimensionArr="dimensionArr"
              :appletTypeIcpStatusList="appletTypeIcpStatusList"
              @anomalydelivery="anomalydelivery"
          ></batchServicePresentation>
        </div>

    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="Span">执行结果 | 只显示错误提示信息</span>
    </el-divider>
    <div>
      <div style="font-size: 17px">未通过数量：{{ messages.length }}</div>
      <el-table
          :data="messages"
          style="width: 100%">
        <el-table-column  label="结果" width="120">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="!scope.row.isCompletedInstructions">失败</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="名字"
            width="350">
        </el-table-column>
        <el-table-column
            prop="operateMessage"
            label="描述"
            width="1300">
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="messages = []">清 空</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import batchServicePresentation from './batchServicePresentation.vue'

let intervalId1 = null
let intervalId2 = null
let intervalId3 = null
let intervalId4 = null

export default {
  components:{
    batchServicePresentation
  },
  props: [
      'tbThirdPartySmallRoutineType', //所有的第三方
      'appletType', //第三方类型 Type
  ],
  data() {
    return {
      //（选项）类别、第三方
      checkAll: true,
      allCategoryList: [], //显示所有类别
      icpStatus: "已备案",
      checkedCities: [], //选中的类别数据
      checkAllType: false,
      dimensionArr: [], //选中的第三方
      appletTypeIcpStatusList: [ //第三方类型的 区分未备案/已备案
        {
          name: "未备案",
          icpStatus: "NOT_REGISTER",
          checked: true
        },
        {
          name: "已备案",
          icpStatus: "REGISTER_SUCCESS",
          checked: true
        }
      ],

      //批量应用配置
      useDeployLoadingA: false,
      appletAmount2: 0,
      percentage2: 0,

      //批量提交审核
      optionModuleList:[], //全部的选项模块
      optionModule: "", //选中的选项模块.groupName
      useDeployLoadingB: false,
      appletAmount1: 0,
      count: 0,
      taskId: null,

      //批量上传版本（构建版本）
      useDeployLoadingC: false,
      wantVersion: "0.0.0",
      appletAmount3: 0,
      percentage3: 0,
      count3: 0,
      taskId3: null,

      //批量驳回退回开发
      useDeployLoadingD: false,
      appletAmount4: 0,
      percentage4: 0,

      //通用
      messages:[], //记录执行的结果
    }
  },
  created() {
    // console.log("类型", this.appletType)
    this.getBatchClassServiceList()
    this.dialogVisible = true;
    this.getOptionModule();
    // console.log("选中的第三方", this.dimensionArr)
    // console.log("选中的类别", this.checkedCities)
  },
  methods: {
    /**
     * 类别选择
     */
    //按钮切换：已备案/未备案
    switchover() {
      this.icpStatus = this.icpStatus === "已备案"? "未备案" : "已备案";
      this.getBatchClassServiceList();
    },
    //获取全部的类别（区分已备案和未备案）
    async getBatchClassServiceList() {
      // console.log("this.appletType", this.appletType)
      const { data: res } = await this.$http.get(
          "/tb-third-party-wang-batch-class/getBatchClassServiceList?appletType=" + this.appletType + "&icpStatus=" + this.icpStatus
      )
      if(res.code == 200){
        this.checkedCities = res.message;
        this.allCategoryList = res.message;
      }else{
        this.$message.error("获取类别失败");
      }
    },
    //获取 第三方类型的 区分未备案/已备案 =》 返回的是 name 值（"已备案/未备案"）
    checkIcpStatus(appletTypeIcpStatusList) {
      // 检查是否所有元素的checked属性都为true
      if (appletTypeIcpStatusList.every(item => item.checked)) {
        return null;
      }
      // 找到第一个checked为true的元素并返回其icpStatus值
      const found = appletTypeIcpStatusList.find(item => item.checked);
      return found ? found.name : null;
    },
    //获取 第三方类型的 区分未备案/已备案 =》 返回的是 icpStatus 值（NOT_REGISTER、REGISTER_SUCCESS）
    checkIcpStatus2(appletTypeIcpStatusList) {
      // 检查是否所有元素的checked属性都为true
      if (appletTypeIcpStatusList.every(item => item.checked)) {
        return null;
      }
      // 找到第一个checked为true的元素并返回其icpStatus值
      const found = appletTypeIcpStatusList.find(item => item.checked);
      return found ? found.icpStatus : null;
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.allCategoryList : [];
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.allCategoryList.length;
    },
    handleCheckAllChangeType(val) {
      this.dimensionArr = val ? this.tbThirdPartySmallRoutineType : [];
    },
    handleCheckedCitiesChangeType(value) {
      let checkedCount = value.length;
      this.checkAllType = checkedCount === this.tbThirdPartySmallRoutineType.length;
    },
    /**
     * 批量应用配置
     */
    //执行选择的多个类别
    async useDeploy() {
      // console.log("选中的类别", this.checkedCities);
      this.useDeployLoadingA = true;
      if (this.checkedCities.length < 1) {
        this.$message.error("请选择类别");
        this.useDeployLoadingA = false
        return
      }
      let batchClassIdList = this.checkedCities.map(city => city.id);
      // console.log("batchClassIdList", batchClassIdList)

      //计算要执行多少小程序
      let multiAppletTypeOrBatchClassIdVo = {
        batchClassIdList: batchClassIdList,
      }
      await this.countAppletAmount2(multiAppletTypeOrBatchClassIdVo);
      //启动进度条
      this.startTaskProgress2();

      const { data: res } = await this.$http.put(
          "/tb-third-party-wang-batch-class/updateBatchClassAppsMultiConfig?batchClassIdList=" + batchClassIdList
      )
      if(res.code == 200){
        this.$message.success("「应用配置」执行完成");
      }else{
        this.$message.error("「应用配置」执行失败");
      }
      //显示结果
      this.messages = res.message;
      // console.log("结果", res.message)
      this.useDeployLoadingA = false

      //任务真正的完成了
      this.percentage2 = 100;
      clearInterval(intervalId2); // 所有任务完成后清除定时器

      //触发父组件 -> 更新小程序列表的数据
      this.$emit('getList');
    },
    //执行选择的多个第三方
    async useDeploythirdparty() {
      this.useDeployLoadingA = true;
      // console.log("选中的第三方", this.dimensionArr)
      if (this.dimensionArr.length < 1) {
        this.$message.error("请选择第三方");
        this.useDeployLoadingA = false
        return
      }
      let appletTypeList = this.dimensionArr.map(city => city.id);
      let appletTypeIcpStatus = this.checkIcpStatus(this.appletTypeIcpStatusList)

      //计算要执行多少小程序
      let multiAppletTypeOrBatchClassIdVo = {
        appletTypeList: appletTypeList,
      }
      await this.countAppletAmount2(multiAppletTypeOrBatchClassIdVo);
      //启动进度条
      this.startTaskProgress2();

      const { data: res } = await this.$http.put(
          "/tb-third-party-wang-batch-class/updateBatchClassAppsMultiConfigThirdparty?appletTypeList=" + appletTypeList + "&appletTypeIcpStatus=" + appletTypeIcpStatus
      )
      if(res.code == 200){
        this.$message.success("「应用配置」执行完成");
      }else{
        this.$message.error("「应用配置」执行失败");
      }
      //记录结果
      this.messages = res.message;
      this.useDeployLoadingA = false

      //任务真正的完成了
      this.percentage2 = 100;
      clearInterval(intervalId2); // 所有任务完成后清除定时器

      //触发父组件 -> 更新小程序列表的数据
      this.$emit('getList');
    },
    //获取 计算要执行多少小程序
    async countAppletAmount2(multiAppletTypeOrBatchClassIdVo) {
      //后台计算有多少小程序
      const { data: res } = await this.$http.post(
          "/tb-third-party-wang-batch-class/countAppletAmount",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.appletAmount2 = res.message;
      } else {
        this.$message.error("计算进度条进度失败:" + res.message);
      }
    },
    //进度条
    startTaskProgress2() {
      this.percentage2 = 0;
      intervalId2 = setInterval(() => {
        if (this.percentage2 < 100) {
          this.percentage2 += Math.round(100 / this.appletAmount2);
        } else {
          // 所有任务完成后清除定时器
          clearInterval(intervalId2);
        }
      }, 300); // 每300毫秒更新一次
    },
    /**
     * 批量提交审核
     */
    //获取全部的选项模块
    async getOptionModule() {
      // 执行接口请求
      const { data: res } = await this.$http(
          "/tb-third-party-small-routine-audit-template/listDigest",
      );
      if (res.code == "200") {
        // this.$message.success("获取成功");
        this.optionModuleList = res.message;
      } else {
        this.$message.error(res.message);
      }
      // console.log("全部选项模块this.optionModuleList", this.optionModuleList)
    },
    //执行提交审核
    async submit_multi_auditApply(is) {
      this.useDeployLoadingB = true; //按钮进入等待状态

      let batchClassIdList = this.checkedCities.map(city => city.id); //选中的类别
      let appletTypeListList = this.dimensionArr.map(city => city.id); //选中的第三方
      if (batchClassIdList.length < 1 && appletTypeListList.length < 1) {
        this.$message.error("请选择类别或第三方");
        this.useDeployLoadingB = false; //解除按钮等待状态
        return
      }
      let appletTypeIcpStatus = this.checkIcpStatus2(this.appletTypeIcpStatusList)

      let multiAppletTypeOrBatchClassIdVo = {
        groupName: this.optionModule,
        batchClassIdList: is === 1? batchClassIdList : null,
        appletTypeList: is === 2? appletTypeListList : null,
        appletTypeIcpStatus: appletTypeIcpStatus
      }

      //计算要执行多少小程序
      await this.countAppletAmount1(multiAppletTypeOrBatchClassIdVo);

      //提交审核
      const { data: res } = await this.$http.post(
          "/thirdParty2/multiAppletTypeOrBatchClassIdAudit",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.$message.warning("「提交审核」已提交，等待审核结果");
      } else {
        this.$message.error("「提交审核」提交失败:" + res.message);
      }

      this.taskId = res.message;

      //查询进度
      this.startTaskProgress1();
    },
    //获取 计算要执行多少小程序
    async countAppletAmount1(multiAppletTypeOrBatchClassIdVo) {
      //后台计算有多少小程序
      const { data: res } = await this.$http.post(
          "/tb-third-party-wang-batch-class/countAppletAmount",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.appletAmount1 = res.message;
      } else {
        this.$message.error("计算进度条进度失败:" + res.message);
      }
    },
    //查询进度
    async startTaskProgress1() {
      this.count = 0; //已完成的数量
      intervalId1 =  setInterval(async () => {
        console.log("执行查询");
        //查询 查询批量审核的进度
        const { data: res } = await this.$http.get(
            "/redisTasksBufferController/getTask?taskId=" + this.taskId
        );
        console.log("审核进度：", res.message)

        if (res.message != null) {
          //进度
          this.count = res.message.auditedAccomplishCount? res.message.auditedAccomplishCount : '';
          //失败信息列表
          this.messages = res.message?.multiAuditApplySbuMsgVoSet? res.message?.multiAuditApplySbuMsgVoSet : [];
        }

        //所有任务进度完成
        if (this.count == this.appletAmount1) {
          //解除按钮等待状态
          this.useDeployLoadingB = false;
          //清除定时器
          clearInterval(intervalId1);
          //触发父组件 -> 更新小程序列表的数据
          this.$emit('getList');
          this.$message.success("「批量提交审核」执行完成");
        }
      }, 5000); // 每5秒查询一次
    },
    /**
     * 批量执行上传版本
     */
    //执行上传版本
    async updateVersions(is) {
      this.useDeployLoadingC = true

      let batchClassIdList = this.checkedCities.map(city => city.id); //选中的类别
      let appletTypeListList = this.dimensionArr.map(city => city.id); //选中的第三方
      if (is === 1 && batchClassIdList.length < 1) {
        this.$message.error("请选择类别");
        this.useDeployLoadingC = false
        return
      }
      if (is === 2 && appletTypeListList.length < 1) {
        this.$message.error("请选择第三方");
        this.useDeployLoadingC = false
        return
      }
      let appletTypeIcpStatus = this.checkIcpStatus2(this.appletTypeIcpStatusList)

      let multiAppletTypeOrBatchClassIdVo = {
        batchClassIdList: is === 1? batchClassIdList : null,
        appletTypeList: is === 2? appletTypeListList : null,
        appletTypeIcpStatus: appletTypeIcpStatus
      }

      //计算要执行多少小程序
      await this.countAppletAmount3(multiAppletTypeOrBatchClassIdVo);

      //版本升级
      const { data: res } = await this.$http.post(
          "/tb-third-party-wang-batch-class/multiAppletTypeOrBatchClassIdUpgrade",
          multiAppletTypeOrBatchClassIdVo
      );
      this.$message.warning("「上传版本」已提交，等待审核结果");

      this.taskId3 = res.message;

      //查询进度
      this.startTaskProgress3();
    },
    //获取 计算要执行多少小程序
    async countAppletAmount3(multiAppletTypeOrBatchClassIdVo) {
      //后台计算有多少小程序
      const { data: res } = await this.$http.post(
          "/tb-third-party-wang-batch-class/countAppletAmount",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.appletAmount3 = res.message;
      } else {
        this.$message.error("计算进度条进度失败:" + res.message);
      }
    },
    //进度条
    async startTaskProgress3() {
      this.count3 = 0; //已完成的数量
      intervalId3 = setInterval(async () => {
        //查询 查询批量审核的进度
        const { data: res } = await this.$http.get(
            "/redisTasksBufferController/getTask?taskId=" + this.taskId3
        );
        // console.log("审核进度：", res.message)

        if (res.message != null) {
          //进度
          this.count3 = res.message.auditedAccomplishCount? res.message.auditedAccomplishCount : '';
          //失败信息列表
          this.messages = res.message?.multiAuditApplySbuMsgVoSet? res.message?.multiAuditApplySbuMsgVoSet : [];
        }

        //所有任务进度完成
        if (this.count3 == this.appletAmount3) {
          //解除按钮等待状态
          this.useDeployLoadingC = false;
          //清除定时器
          clearInterval(intervalId3);
          //触发父组件 -> 更新小程序列表的数据
          this.$emit('getList');
          this.$message.success("「批量版本升级」执行完成");
        }
      }, 1500); // 每1500毫秒查询一次
    },
    /**
     * 批量驳回退回开发
     */
    //执行退回开发
    async multiAuditedCancel(is) {
      this.useDeployLoadingD = true

      let batchClassIdList = this.checkedCities.map(city => city.id); //选中的类别
      let appletTypeListList = this.dimensionArr.map(city => city.id); //选中的第三方
      if (is === 1 && batchClassIdList.length < 1) {
        this.$message.error("请选择类别");
        this.useDeployLoadingD = false
        return
      }
      if (is === 2 && appletTypeListList.length < 1) {
        this.$message.error("请选择第三方");
        this.useDeployLoadingD = false
        return
      }
      let appletTypeIcpStatus = this.checkIcpStatus2(this.appletTypeIcpStatusList)

      // console.log("选中的", appletTypeListList)
      let multiAppletTypeOrBatchClassIdVo = {
        batchClassIdList: is === 1? batchClassIdList : null,
        appletTypeList: is === 2? appletTypeListList : null,
        appletTypeIcpStatus: appletTypeIcpStatus
      }

      //计算要执行多少小程序
      await this.countAppletAmount4(multiAppletTypeOrBatchClassIdVo);
      //启动进度条
      this.startTaskProgress4();

      const { data: res } = await this.$http.post(
          "/thirdParty2/multiAuditedCancel",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.$message.success("「退回开发」执行完成");
      } else {
        this.$message.error("「退回开发」执行失败:" + res.message);
      }

      this.useDeployLoadingD = false

      //任务真正的完成了
      this.percentage4 = 100;
      clearInterval(intervalId4); // 所有任务完成后清除定时器

      //触发父组件 -> 更新小程序列表的数据
      this.$emit('getList');
    },
    //获取 计算要执行多少小程序
    async countAppletAmount4(multiAppletTypeOrBatchClassIdVo) {
      //后台计算有多少小程序
      const { data: res } = await this.$http.post(
          "/tb-third-party-wang-batch-class/countAppletAmount",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.appletAmount4 = res.message;
      } else {
        this.$message.error("计算进度条进度失败:" + res.message);
      }
    },
    //进度条
    startTaskProgress4() {
      this.percentage4 = 1;
      intervalId4 = setInterval(() => {
        if (this.percentage4 < 100) {
          this.percentage4 += Math.round(100 / this.appletAmount4);
        } else {
          // 所有任务完成后清除定时器
          clearInterval(intervalId4);
        }
      }, 300); // 每300毫秒更新一次
    },
    /**
     * 批量服务提报，子传递失败信息列表
     */
    anomalydelivery(messages) {
      this.messages = messages;
    }

  }
}

</script>

<style scoped lang="less">
.Span{
  color: #ff8b8b;
  font-size: 20px;
  font-weight: bold;
}
.SpanA{
  color: #938bff;
  font-size: 20px;
  font-weight: bold;
}
.fg{
  margin-top: 100px;
}
</style>